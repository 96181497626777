<template>
  <div>
    <c-search-box>
      <template slot="search">
        <div v-if="educationInfo.plantName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-label-text title="LBLPLANT" :value="educationInfo.plantName"></c-label-text>
        </div>
        <!-- 교육과정 -->
        <div v-if="educationInfo.educationCourseName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-label-text title="LBL0002930" :value="educationInfo.educationCourseName"></c-label-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 교육기간 -->
          <c-datepicker
            :range="true"
            name="educationPeriod"
            defaultStart="-6M"
            defaultEnd="0M"
            label="LBL0002829"
            v-model="educationPeriod"
          ></c-datepicker>
        </div>
      </template>
    </c-search-box>
      <c-table
        ref="table"
        title="교육과정별 교육계획"
        :columns="grid.columns"
        :data="grid.data"
        :editable="editable"
        :merge="grid.merge"
        rowKey="eduEducationId"
        checkDisableColumn="disable"
        :checkClickFlag="false"
        :isFullScreen="false"
        :filtering="false"
        :isExcelDown="false"
        :columnSetting="false"
        @linkClick="linkClick"
      >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <!-- 검색 -->
            <c-btn label="LBLSEARCH"  icon="search" @btnClicked="getHistoryList" />
          </q-btn-group>
        </div>
      </template>
      </c-table>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
</template>
<script>

import selectConfig from '@/js/selectConfig';
export default {
  name: 'education-course-history',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        eduCourseId: '',
      }),
    },
    educationInfo: {
      type: Object,
      default: () => ({
        plantName: '',
        educationCourseName: '',
        educationKindCdLargeName: '',
        educationKindCdSmallName: '',
        educationTypeName: '',
        legalEducationFlagName: '',
        educationPurpose: '',
        educationTimeName: '',
        relatedLawsName: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: "educationName",
            field: "educationName",
            // 교육명
            label: "LBL0002929",
            style: 'width:250px',
            align: "left",
            sortable: true,
            type: "link",
          },
          // {
          //   name: "documentStatusName",
          //   field: "documentStatusName",
          //   label: "진행상태",
          //   style: 'width:90px',
          //   align: "center",
          //   sortable: true,
          // },
          {
            name: "educationDate",
            field: "educationDate",
            // 교육기간
            label: "LBL0002829",
            style: 'width:250px',
            align: "center",
            sortable: true,
          },
          {
            name: 'educationLocation',
            field: 'educationLocation',
            // 교육장소
            label: 'LBL0002839',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: "educationMethodName",
            field: "educationMethodName",
            style: 'width:90px',
            // 교육방법
            label: "LBL0002936",
            align: "center",
            sortable: true,
          },
          {
            name: "educationPurpose",
            field: "educationPurpose",
            style: 'width:300px',
            // 학습목적
            label: "LBL0002937",
            align: "left",
            sortable: true,
          },
        ],
        height: '500px',
        data: [],
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      educationPeriod: [],
      excelUrl: '',
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.edu.result.list.url;
      this.getHistoryList();
    },
    getHistoryList() {
      if (this.popupParam.eduCourseId) {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.educationInfo.plantCd,
          eduCourseId: this.popupParam.eduCourseId,
          educationPeriod: this.educationPeriod,
          exceptDocumentStatusCd: 'ESC000001'
        };
        this.$http.request((_result) => {
          this.grid.data = _result.data
        },);
      }
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row) {
      this.popupOptions.title = "LBL0002840"; // 교육계획 및 결과 상세
      this.popupOptions.param = {
        eduEducationId: row ? row.eduEducationId : '',
        eduCourseId: this.educationInfo.eduCourseId,
        stepCd: !row || row.documentStatusCd === 'ESC000001' ?  'ESC0000005' : 'ESC0000010',
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/sop/edu/result/educationResultDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getHistoryList();
    },
  }
};
</script>